import { ColDef, GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";
import { FileStatusRenderer } from "src/app/feature-file-explorer/tree-view/file-status-renderer.component";
import { TreeCellRendererComponent } from "src/app/feature-file-explorer/tree-view/tree-view.component";
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges } from "@angular/core";
import { FileSelectorRenderer } from "src/app/feature-recipe/recipe-run/tree-view/file-selector-renderer.component";
import { StorageService } from "src/app/services/storage.service";
import "../string-extensions/string-extensions";
import * as moment from 'moment';
import { DataDropService } from "src/app/services/http.datadrop.service";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, of } from "rxjs";

@Component({
    selector: 'app-folder-grid',
    templateUrl: './folder-grid.component.html',
})

export class FolderGridComponent implements OnInit {
    @Input() gridApi: GridApi;
    @Input() gridOptions: GridOptions;
    @Input() selectable: boolean;
    @Input() selectedFiles: Array<any>;
    @Input() scope: any;
    public rowData = [];
    public columnDefs: ColDef[];

    public data = [];
    private customIdCount = 0;
    private currentPath: string;
 
    @Output() selectedDataset = new EventEmitter<any>();
    @Output() rowSelected = new EventEmitter<any>();
    @Output() gridOptionsChange = new EventEmitter<GridOptions>();
    savedPositionInGrid;
    resultArr;

    constructor(private renderer: Renderer2,
        private storageService: StorageService,
        private dataDropService: DataDropService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.columnDefs = [
            {
                headerName: 'Folder',
                field: 'folder',
                minWidth: 300,
                cellRenderer: TreeCellRendererComponent
            },
            this.selectable ? 
                { headerName: 'File', field: 'file', cellRenderer: FileSelectorRenderer, cellRendererParams: {
                    context: {
                    onFileSelected: this.onFileSelected.bind(this)
                    }
                } } : 
                { headerName: 'File', field: 'file' },
                { 
                    headerName: '', 
                    cellRenderer: FileStatusRenderer,
                    cellRendererParams: params => ({
                        fileState: params.data.fileState,
                        convertedFrom: params.data.convertedFrom
                    })
                },
            { headerName: 'Date', field: 'date' },
            { headerName: 'Storage Type', field: 'storageType' },
            { headerName: 'File Size', field: 'fileSize' },
            { headerName: 'Created By', field: 'createdBy'}
        ];

        this.gridOptions = {
            ...this.gridOptions,
            rowSelection: 'single',
            onRowSelected: event => this.onRowSelected(event),
            onRowClicked: event => this.onRowClicked(event)
        };
        this.gridOptions.context = { componentParent: this };
        this.gridOptionsChange.emit(this.gridOptions);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['scope']?.currentValue != undefined) {
            this.currentPath = this.getFolderPath(this.scope?.customer, this.scope?.sourceSystem, this.scope?.product, this.scope?.lob);
            this.update(this.currentPath);
        }
    }
    
    update(currentPath: string): void {
        if (currentPath != null) {
            this.data = [];
            this.rowData = this.data;
            const storageListObservable = this.storageService.getList(currentPath);
            const recipeId = this.route.snapshot.queryParams.recipeId;
    
            const customerDatasetsObservable = recipeId 
                ? this.dataDropService.getCustomerDatasetsInRecipe(recipeId) 
                : of(null);
    
            forkJoin({
                storageList: storageListObservable,
                customerDatasets: customerDatasetsObservable
            }).subscribe(({ storageList, customerDatasets }) => {
                this.data = this.mapFolderContent(storageList.s3Files, currentPath);
                if (customerDatasets) {
                    let datasetNames: string[] = Array.isArray(customerDatasets) ? customerDatasets : [];
                    if (datasetNames.length === 0) {
                        datasetNames = [this.route.snapshot.queryParams.datasetName];
                    }
                    if (!datasetNames.includes(this.route.snapshot.queryParams.datasetName)) {
                        datasetNames.push(this.route.snapshot.queryParams.datasetName);
                    }
                    const datasetPrefixes = datasetNames.map(x => `dataset=${x}/`.toLowerCase());
                    
                    this.data = this.data.filter(x => {
                        const lowerCaseName = x.name.toLowerCase(); 
                        return datasetPrefixes.some(prefix => lowerCaseName.startsWith(prefix));
                    });
                }
                this.resultArr = this.data;
                this.addIdInDataRecursive(this.data);
                this.rowData = this.data;
            }, error => {
                console.error('Error in retrieving data', error);
            });
        }
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        let columnDefs = this.gridApi.getColumnDefs();
        let folderColumn: ColDef = columnDefs.find(x => x.headerName === "Folder");
        folderColumn.cellRendererParams = { renderer: this.renderer };
        this.columnDefs = columnDefs;
    }

    onRowClicked(event){
        this.savedPositionInGrid = event.node.rowIndex;
    }

    onNodeClick(customId: number) {
        let node = this.recursiveFindById(this.data, customId);
        if (node && !node.isFile) {
            if (this.selectable !== true) {
                this.selectDataset(node);
            }
            if (node.expanded || !node.expanded && node.children) {
                this.rebuildTree(node);
                return;
            }
            const childrenPath = node.path + node.name;
            this.storageService.getList(childrenPath)
                .subscribe(result => {
                    let children = this.mapFolderContent(result.s3Files, childrenPath);
                    node.children = children;
                    this.rebuildTree(node);
                    if(children.length > 0) {
                        this.currentPath = childrenPath;
                    }
                    else{
                        this.currentPath = node.path;
                    }                   
                })
        }
    }

    onRowSelected(event) {
        if (event.node.isSelected()) {
            this.rowSelected.emit(event.data);
        }
    }

    public updateGridWithData(newData: any[]): void {
        this.rowData = newData;
        this.gridApi?.setRowData(this.rowData);
    }

    private selectDataset(node: any) {
        if (node.name.startsWith('dataset')) {
            this.selectedDataset.emit(node);
        } else {
            this.selectedDataset.emit(null);
        }
    }

    private onFileSelected(selected: boolean, node: any) {
        let filename = node.path + node.file;
        let index = this.selectedFiles.findIndex(v=>v===filename);
        if(index>=0){
            if(selected===false){
                this.selectedFiles.splice(index, 1); 
            }
        } else {
            if(selected===true){
                this.selectedFiles.push(filename);
            }
        }
    }
    
    private mapFolderContent(folderContent: Array<any>, folderPath: string) {
        return folderContent.map(x => {
            if (x.isFolder) {
                return {
                    folder: x.name,
                    isFile: false,
                    path: folderPath,
                    name: x.name,
                    isMaterializedDataset: x.isMaterializedDataset,
                    materializedDatasetId: x.materializedDatasetId
                }
            } else {
                return {
                    file: x.name,
                    isFile: true,
                    fileState: x.pendingUpload ? "Pending upload" : "Uploaded",
                    path: folderPath,
                    name: x.name,
                    date: moment(x.lastModified).format("MM/DD/YYYY HH:mm:ss"),
                    storageType: x.storageClass,
                    fileSize: x.size,
                    presignedUrl: x.presignedUrl,
                    selected: false,
                    createdBy: x.user,
                    convertedFrom: x.convertedFromExcel
                }
            }
        });
    }

    private rebuildTree(node: any) {
        node.expanded = !node.expanded;
        let rowDataExpanded = [];
        this.makeDataRecursive(this.data, 0, rowDataExpanded);
        this.addIdInDataRecursive(rowDataExpanded);
        setTimeout(() => {
            this.gridApi?.setRowData(rowDataExpanded);
            const newLastRow = this.gridApi.getDisplayedRowCount() - 1;
            this.savedPositionInGrid = Math.min(this.savedPositionInGrid, newLastRow);
        
            const firstVisibleRow = this.gridApi?.getFirstDisplayedRow();
            const lastVisibleRow = this.gridApi?.getLastDisplayedRow();
            const isVisible = this.savedPositionInGrid >= firstVisibleRow && this.savedPositionInGrid <= lastVisibleRow;
        
            let toBeFocused = isVisible ? this.savedPositionInGrid : Math.min(this.savedPositionInGrid, newLastRow);
            if(this.resultArr?.length < rowDataExpanded?.length){
                toBeFocused += 1;
            }
            this.resultArr = rowDataExpanded;
            this.gridApi.setFocusedCell(this.savedPositionInGrid, 'folder');
            this.gridApi.ensureIndexVisible(toBeFocused, 'middle');
            this.gridApi.getRowNode(this.savedPositionInGrid.toString())?.setSelected(false, true);
        }, 50);
    }
    
    
    private addIdInDataRecursive(data) {
        data.forEach(e => {
            if (e.customId == undefined && e.customId !== 0) {
                e.customId = this.customIdCount;
                this.customIdCount++;
            }
            if (e.children) {
                this.addIdInDataRecursive(e.children);
            }
        });
    }
    
    private makeDataRecursive(arrayData: Array<any>, level: number, rowDataExpanded: Array<any>): void {
        arrayData.forEach(mainRow => {
            mainRow.level = level;
            rowDataExpanded.push(mainRow);
            if (mainRow.expanded) {
                this.makeDataRecursive(mainRow.children, level + 20, rowDataExpanded);
            }
        });
    }

    private recursiveFindById(arrayData: Array<any>, customId: number): any {
        let foundData = arrayData.find(e => e.customId === customId);
        if (foundData) {
            return foundData;
        }
        arrayData.every(e => {
            if (e.children) {
                foundData = this.recursiveFindById(e.children, customId);
            }
            if (foundData) {
                return false;
            }
            return true;
        });
        return foundData;
    }

    getFolderPath(customerName: string, sourceSystemName: string, productName: string, lobName: string): string {
        let path = `customer=${customerName?.sanitize()}`;
        if (sourceSystemName != undefined || productName != undefined) {
            path += `/source_system=${sourceSystemName.sanitize()}`;
            if (productName != undefined) {
                path += `/product=${productName.sanitize()}`;
                if (lobName != undefined) {
                    path += `/tag=${lobName.sanitize()}`;
                }
            }
        }
        path = path + '/';
        return path;
    }
}